import React, { useState } from 'react'
import { Button, Checkbox, Form, Input, Modal, FormProps, Row, Col } from 'antd'
import { LoginOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { MaskedInput } from 'antd-mask-input'
import { AxiosError } from 'axios'
import { Address, useUserStateDispatch } from '../../contexts'
import { api } from '../../services'
import { useNotificationStateContext } from '../../contexts'
import { telegramLink } from '../../constants'
import './AuthModal.css'

interface AuthorizationModalProps {
    open: boolean
    setOpen: (open: boolean) => void
}

type LoginFieldType = {
    phone?: string
    password?: string
    remember?: string
}

const AuthModal = ({ open, setOpen }: AuthorizationModalProps) => {
    const [loading, setLoading] = useState(false)
    const userDispatch = useUserStateDispatch()
    const notificationState = useNotificationStateContext()
    const { t } = useTranslation()
    const [isAuthError, setIsAuthError] = useState(false)

    const onLogin: FormProps<LoginFieldType>['onFinish'] = async (values) => {
        setLoading(true)
        setIsAuthError(false)

        await api
            .post('/login', {
                phone: (values.phone || '').replace(/\D/g, ''),
                password: values.password,
            })
            .then(async function (response) {
                await api
                    .get('/addresses')
                    .then(function (response) {
                        userDispatch({
                            type: 'setAddresses',
                            payload: response.data.map((address: Address) => {
                                return {
                                    value: address.id,
                                    label: `${address.city}, ${address.street} ${address.house}${address.flat ? ', ' + t('authModal.flat') + ' ' + address.flat : ''}`,
                                }
                            }),
                        })
                    })
                    .catch(function (error) {
                        throw error
                    })

                const user = response.data

                userDispatch({
                    type: 'setUser',
                    payload: user,
                })

                notificationState.welcomeNotification(user.userName as string)

                setOpen(false)
            })
            .catch(function (error) {
                if (error instanceof AxiosError) {
                    if (error.response?.status === 401) {
                        setIsAuthError(true)
                        return
                    }
                }

                notificationState.openErrorNotification({
                    description: `${error}`,
                })
                console.log(error)
            })
            .finally(function () {
                setLoading(false)
            })
    }

    const onFinishFailed: FormProps<LoginFieldType>['onFinishFailed'] = (
        errorInfo
    ) => {
        console.log('Failed:', errorInfo)
    }

    return (
        <>
            <Modal
                title={t('authModal.title')}
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                footer={<></>}
            >
                <div className="AuthorizationModal">
                    <div className="AuthorizationModalForm">
                        <Form
                            name="basic"
                            style={{ width: '100%' }}
                            initialValues={{ remember: true }}
                            onFinish={onLogin}
                            onFinishFailed={onFinishFailed}
                            autoComplete="no"
                            disabled={loading}
                            layout="vertical"
                        >
                            <Row gutter={[24, 24]} justify="center">
                                <Col span={18}>
                                    <Form.Item<LoginFieldType>
                                        label={t('authModal.form.phone')}
                                        name="phone"
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'authModal.form.phoneValidationMessage'
                                                ),
                                            },
                                        ]}
                                    >
                                        <MaskedInput
                                            placeholder={t('orderForm.phone')}
                                            size="large"
                                            mask={'+374(00)000-000'}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={[24, 24]} justify="center">
                                <Col span={18}>
                                    <Form.Item<LoginFieldType>
                                        label={t('authModal.form.password')}
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'authModal.form.passwordValidationMessage'
                                                ),
                                            },
                                        ]}
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={[24, 24]} justify="center">
                                <Col span={18}>
                                    <Form.Item<LoginFieldType>
                                        name="remember"
                                        valuePropName="checked"
                                    >
                                        <Checkbox>
                                            {t('authModal.form.rememberMe')}
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={[24, 24]} justify="center">
                                <Col span={18}>
                                    <Form.Item>
                                        <Button
                                            className="SubmitBtn"
                                            type="primary"
                                            shape="round"
                                            size={'large'}
                                            style={{
                                                backgroundColor:
                                                    'var(--color-yellow)',
                                                width: '100%',
                                            }}
                                            htmlType="submit"
                                            icon={<LoginOutlined />}
                                        >
                                            {t('authModal.form.loginBtn')}
                                        </Button>

                                        {isAuthError ? (
                                            <p className="ErrorAuth ant-form-item-explain-error">
                                                Неверный логин или пароль!{' '}
                                                <a
                                                    href={telegramLink}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Свяжитесь с нами для
                                                    восстановления данных
                                                </a>
                                            </p>
                                        ) : null}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default AuthModal
