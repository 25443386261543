import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Form,
    FormInstance,
    Input,
    Radio,
    Row,
    Select,
    Space,
} from 'antd'
import { MaskedInput } from 'antd-mask-input'
import { PlusOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { useUserStateContext } from '../../contexts'
import { RangePickerProps } from 'antd/es/date-picker'
import { mapLink } from '../../constants'
import './OrderForm.css'

interface OrderFormProps {
    form: FormInstance
    initialValues: {
        deliveryMethod: string
    }
    loading: boolean
    isRegister: boolean
}

const { TextArea } = Input

const colProps = {
    xs: { flex: '100%' },
    sm: { flex: '100%' },
    md: { flex: '100%' },
    lg: { flex: '50%' },
    xl: { flex: '50%' },
}

const OrderForm = ({
    form,
    initialValues,
    loading,
    isRegister,
}: OrderFormProps) => {
    const { t } = useTranslation()
    const userState = useUserStateContext()
    const [openAddressForm, setOpenAddressForm] = useState<boolean>(false)

    const options = [
        { label: t('orderForm.deliveryMethodOption1'), value: 'delivery' },
        { label: t('orderForm.deliveryMethodOption2'), value: 'pickup' },
    ]
    const style: React.CSSProperties = {
        width: '100%',
    }

    const deliveryMethod = Form.useWatch('deliveryMethod', form)

    useEffect(() => {
        setOpenAddressForm(!userState.addresses?.length)
    }, [userState])

    const disabledDateTime = () => ({
        disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 23],
    })

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        return current && current < dayjs().add(-1, 'day').endOf('day')
    }

    return (
        <Form
            form={form}
            layout="vertical"
            size="large"
            disabled={loading}
            initialValues={initialValues}
        >
            <Row gutter={[24, 0]}>
                <Col {...colProps}>
                    <Form.Item
                        name="userName"
                        label={t('orderForm.userName')}
                        rules={[
                            {
                                required: true,
                                message: t(
                                    'orderForm.userNameValidationMessage'
                                ),
                            },
                        ]}
                    >
                        <Input
                            placeholder={t('orderForm.userName')}
                            style={style}
                        />
                    </Form.Item>
                </Col>

                <Col {...colProps}>
                    <Form.Item
                        name="email"
                        label={t('orderForm.email')}
                        rules={[
                            {
                                type: 'email',
                                required: true,
                                message: t('orderForm.emailValidationMessage'),
                            },
                        ]}
                    >
                        <Input
                            placeholder={t('orderForm.email')}
                            style={style}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[24, 0]}>
                <Col {...colProps}>
                    <Form.Item
                        name="phone"
                        label={t('orderForm.phone')}
                        rules={[
                            {
                                required: true,
                                message: t('orderForm.phoneValidationMessage'),
                            },
                        ]}
                    >
                        <MaskedInput
                            placeholder={t('orderForm.phone')}
                            size="large"
                            mask={'+374(00)000-000'}
                        />
                    </Form.Item>
                </Col>

                <Col {...colProps}>
                    <Form.Item
                        label="Telegram"
                        name="telegram"
                        help={t('orderForm.telegramHint')}
                        style={{
                            textAlign: 'left',
                        }}
                    >
                        <Input addonBefore="@" />
                    </Form.Item>
                </Col>
            </Row>

            {isRegister ? (
                <Row gutter={[24, 0]}>
                    <Col {...colProps}>
                        <Form.Item
                            label={t('orderForm.password')}
                            name="password"
                        >
                            <Input.Password />
                        </Form.Item>
                    </Col>

                    <Col {...colProps}>
                        <Form.Item
                            label={t('orderForm.confirmPassword')}
                            name="confirmPassword"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (
                                            !value ||
                                            getFieldValue('password') === value
                                        ) {
                                            return Promise.resolve()
                                        }
                                        return Promise.reject(
                                            new Error(
                                                t(
                                                    'orderForm.passwordValidationMessage'
                                                )
                                            )
                                        )
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                    </Col>
                </Row>
            ) : null}

            <Row gutter={[24, 0]}>
                <Col>
                    <Form.Item
                        name="deliveryMethod"
                        label={t('orderForm.deliveryMethod')}
                    >
                        <Radio.Group
                            options={options}
                            value="delivery"
                            optionType="button"
                            buttonStyle="solid"
                            style={style}
                            size="large"
                        />
                    </Form.Item>
                </Col>
            </Row>

            {deliveryMethod !== 'pickup' ? (
                <>
                    {userState.addresses?.length && !openAddressForm ? (
                        <Row gutter={[24, 24]}>
                            <Col {...colProps}>
                                <Form.Item
                                    name="addressId"
                                    label={t('orderForm.address')}
                                    rules={[
                                        {
                                            required: true,
                                            message: t(
                                                'orderForm.addressValidationMessage'
                                            ),
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder={t(
                                            'orderForm.selectAddress'
                                        )}
                                        optionFilterProp="label"
                                        options={userState.addresses}
                                        style={{
                                            width: '100%',
                                        }}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                <Divider
                                                    style={{ margin: '8px 0' }}
                                                />
                                                <Space
                                                    style={{
                                                        padding: '0 8px 4px',
                                                    }}
                                                >
                                                    <Button
                                                        type="text"
                                                        icon={<PlusOutlined />}
                                                        onClick={() =>
                                                            setOpenAddressForm(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        {t(
                                                            'orderForm.otherAddress'
                                                        )}
                                                    </Button>
                                                </Space>
                                            </>
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : null}

                    {openAddressForm ? (
                        <>
                            <Row gutter={[24, 0]}>
                                <Col {...colProps}>
                                    <Form.Item
                                        name="city"
                                        label={t('orderForm.city')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'orderForm.cityValidationMessage'
                                                ),
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder={t('orderForm.city')}
                                            style={style}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col {...colProps}>
                                    <Form.Item
                                        name="street"
                                        label={t('orderForm.street')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'orderForm.streetValidationMessage'
                                                ),
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder={t('orderForm.street')}
                                            style={style}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={[24, 0]}>
                                <Col {...colProps}>
                                    <Form.Item
                                        name="house"
                                        label={t('orderForm.house')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'orderForm.houseValidationMessage'
                                                ),
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder={t('orderForm.house')}
                                            style={style}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col {...colProps}>
                                    <Form.Item
                                        name="flat"
                                        label={t('orderForm.flat')}
                                    >
                                        <Input
                                            placeholder={t('orderForm.flat')}
                                            style={style}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    ) : null}

                    <Row gutter={[24, 0]}>
                        <Col {...colProps}>
                            <Form.Item
                                name="deliveryTime"
                                label={t('orderForm.deliveryTime')}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input!',
                                    },
                                ]}
                            >
                                <DatePicker
                                    format="YYYY-MM-DD HH:mm"
                                    disabledDate={disabledDate}
                                    disabledTime={disabledDateTime}
                                    showTime
                                    style={style}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            ) : (
                <div className="ant-upload-hint OrderFormPickup">
                    <p>
                        {t('orderForm.map')}
                        <a href={mapLink} target="_blank" rel="noreferrer">
                            {t('orderForm.pickupAddress')}
                        </a>
                    </p>
                </div>
            )}

            <Row gutter={[24, 0]}>
                <Col flex="100%">
                    <Form.Item
                        name="comment"
                        label={t('orderForm.comment')}
                        help={
                            <ul>
                                {t('orderForm.commentHint1')}
                                <li
                                    style={{
                                        marginLeft: 14,
                                    }}
                                >
                                    {t('orderForm.commentHint2')}
                                </li>
                                <li
                                    style={{
                                        marginLeft: 14,
                                    }}
                                >
                                    {t('orderForm.commentHint3')}
                                </li>
                                <li
                                    style={{
                                        marginLeft: 14,
                                    }}
                                >
                                    {t('orderForm.commentHint4')}
                                </li>
                            </ul>
                        }
                        style={{
                            textAlign: 'left',
                        }}
                    >
                        <TextArea
                            rows={4}
                            style={style}
                            placeholder={t('orderForm.commentPlaceholder')}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default OrderForm
