import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import './Footer.css'
import { instagramLink, phone, telegramLink } from '../../constants'

const Footer = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    return (
        <div className="Footer">
            <div className="FooterWrapper">
                <div className="FooterLeftSide">
                    <strong className="footerSideTitle">
                        {t('footer.contactUs')}
                    </strong>

                    <p>
                        <a
                            className="FooterPhone"
                            onClick={() => navigate('/about')}
                            rel="noreferrer"
                        >
                            {t('footer.aboutCompany')}
                        </a>
                    </p>

                    <p>
                        <a
                            className="FooterPhone"
                            href="tel:+37493552175"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {phone}
                        </a>
                    </p>

                    <p>
                        <div>{t('footer.openingHours')}:</div>
                        <div>10:00 - 22:00</div>
                    </p>

                    <p className="FooterSocial">
                        <a
                            href={instagramLink}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                className="SocialIcon"
                                src="instagram_s.svg"
                                alt="instagram"
                                width="40px"
                            />
                        </a>

                        <a href={telegramLink} target="_blank" rel="noreferrer">
                            <img
                                className="SocialIcon"
                                src="telegram_s.svg"
                                alt="telegram"
                                width="40px"
                            />
                        </a>
                    </p>
                </div>
                <div className="FooterRightSide"></div>
            </div>
        </div>
    )
}

export default Footer
