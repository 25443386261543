import React from 'react'
import { Layout as LayoutComp } from 'antd'
import { Content, Footer } from 'antd/es/layout/layout'
import { Outlet } from 'react-router-dom'
import { Header } from '../Header'
import { Footer as FooterComp } from '../Footer'
import { ResponseInterceptor } from '../ResponseInterceptor'
import { AuthModalProvider, NotificationStateContext } from '../../contexts'
import './Layout.css'

const contentStyle: React.CSSProperties = {
    textAlign: 'center',
    minHeight: '100%',
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: 'var(--color-white)',
}

const footerStyle: React.CSSProperties = {}

const layoutStyle = {
    overflow: 'hidden',
    width: '100%',
    maxWidth: '100%',
    minHeight: '100%',
}

const Layout = () => {
    return (
        <NotificationStateContext>
            <AuthModalProvider>
                <LayoutComp style={layoutStyle}>
                    <Header />
                    <Content style={contentStyle}>
                        <Outlet />
                        <ResponseInterceptor />
                    </Content>
                    <Footer style={footerStyle}>
                        <FooterComp />
                    </Footer>
                </LayoutComp>
            </AuthModalProvider>
        </NotificationStateContext>
    )
}

export default Layout
