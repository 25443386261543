import axios from 'axios'
import { Address, Category, Order, Products, User } from '../contexts'

export const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL + '/api',
    withCredentials: true,
    timeout: 12000,
    headers: {
        'Content-Type': 'application/json',
    },
})

export const getUser = async (): Promise<User> => {
    const response = await api.get<User>('/user')
    return response.data
}

export const getCategories = async (): Promise<Category[]> => {
    const response = await api.get<Category[]>('/categories')
    return response.data
}

export const getProducts = async (): Promise<Products> => {
    const response = await api.get('/products')
    return response.data
}

export const getOrders = async (): Promise<Order[]> => {
    const response = await api.get('/orders')
    return response.data
}

export const getAddresses = async (): Promise<Address[]> => {
    const response = await api.get('/addresses')
    return response.data
}
