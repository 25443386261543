import React from 'react'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import './Count.css'

interface CountProps {
    value: number
    setCount: (count: number) => void
}

const Count = (props: CountProps) => {
    const increment = () => {
        props.setCount(props.value + 1)
    }

    const decrement = () => {
        props.setCount(props.value - 1 || 1)
    }

    return (
        <div className="Count">
            <button className="CountControl" onClick={decrement}>
                <MinusCircleOutlined style={{ fontSize: '20px' }} />
            </button>
            <div className="CountNum">{props.value}</div>
            <button className="CountControl" onClick={increment}>
                <PlusCircleOutlined style={{ fontSize: '20px' }} />
            </button>
        </div>
    )
}

export default Count
