import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Form, FormProps, Tabs } from 'antd'
import { TabsProps } from 'antd/lib'

import { api, getAddresses } from '../../services'
import {
    useNotificationStateContext,
    useUserStateContext,
    useUserStateDispatch,
} from '../../contexts'
import { OrdersTable, SignUpForm } from '../../components'

import { addressesToClientFormat } from '../../utils'
import './AccountPage.css'

export type RegistrationFieldType = {
    email?: string
    phone?: string
    telegram?: string
    userName?: string
    addressId?: string
    password?: string
    confirmPassword?: string
    city?: string
    street?: string
    house?: string
    flat?: string
    promoCode?: string
}

const UserForm = () => {
    const [form] = Form.useForm()
    const userDispatch = useUserStateDispatch()
    const userState = useUserStateContext()
    const notificationState = useNotificationStateContext()
    const [addressId, setAddressId] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)

    const onChangeAddress = (value: string) => {
        setAddressId(value)
    }

    useEffect(() => {
        form.setFieldsValue({
            userName: userState?.user?.userName,
            email: userState?.user?.email,
            phone: userState?.user?.phone,
            telegram: userState?.user?.telegram,
            promoCode: userState.user?.promoCode?.value
        })

        setAddressId(userState?.user?.addressId)
    }, [userState?.user])

    const updatePassword = async (
        password: string,
        confirmPassword: string
    ) => {
        await api
            .patch('/user/password', {
                password,
                confirmPassword,
            })
            .then(() => {})
            .catch(function (error) {
                notificationState.openErrorNotification({
                    description: `${error}`,
                })
                console.log(error)
            })
    }

    const onSubmit: FormProps<RegistrationFieldType>['onFinish'] = async (
        values
    ) => {
        setLoading(true)

        let newAddressId = undefined
        if (values.password && values.confirmPassword) {
            updatePassword(values.password, values.confirmPassword)
        }

        if (values.city && values.street && values.house) {
            await api
                .post('/address', {
                    city: values.city,
                    street: values.street,
                    house: values.house,
                    flat: values.flat,
                })
                .then(async (response) => {
                    newAddressId = response.data.value

                    await getAddresses()
                        .then((addresses) => {
                            userDispatch({
                                type: 'setAddresses',
                                payload: addressesToClientFormat(addresses),
                            })
                        })
                })
                .catch(function (error) {
                    notificationState.openErrorNotification({
                        description: `${error}`,
                    })
                    console.log(error)
                })
        }

        await api
            .patch('/user', {
                userName: values.userName,
                phone: (values.phone || '').replace(/\D/g, ''),
                email: values.email,
                telegram: values.telegram,
                addressId: newAddressId ?? addressId,
                password: values.password,
                promoCode: values.promoCode,
                favoriteProducts: userState.user?.favoriteProducts || []
            })
            .then(function (response) {
                userDispatch({
                    type: 'setUser',
                    payload: response.data,
                })

                notificationState.updateUserNotification()
            })
            .catch(function (error) {
                notificationState.openErrorNotification({
                    description: `${error}`,
                })
                console.log(error)
            })

        setLoading(false)
    }

    return (
        <SignUpForm
            form={form}
            loading={loading}
            onSubmit={onSubmit}
            onChangeAddress={onChangeAddress}
        />
    )
}

const AccountPage = () => {
    const { t } = useTranslation()

    const items: TabsProps['items'] = [
        { key: '1', label: t('accountPage.profile'), children: <UserForm /> },
        { key: '2', label: t('accountPage.orders'), children: <OrdersTable /> },
    ]

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://coffochka.am/account" />
            </Helmet>

            <div className="AccountPage">
                <div className="AccountPageWrapper">
                    <Tabs
                        defaultActiveKey="1"
                        items={items}
                        indicator={{ size: (origin) => origin - 20 }}
                        centered
                    />
                </div>
            </div>
        </>
    )
}

export default AccountPage
