import React from 'react'
import {
    BasketProducts as BasketProductsType,
    Products,
    useUserStateContext,
} from '../../contexts'
import BasketProductComp from '../BasketProduct/BasketProduct'
import './BasketProducts.css'

export interface BasketProductsProps {
    products: BasketProductsType
    imgSize?: string
}

const BasketProducts = ({ products, imgSize }: BasketProductsProps) => {
    const userState = useUserStateContext()
    const getProducts = () => {
        if (userState.products) {
            return Object.keys(products).map((key) => (
                <BasketProductComp
                    product={(userState.products as Products)[key]}
                    count={products[key].count}
                    imgSize={imgSize}
                    key={products[key].id}
                />
            ))
        }

        return null
    }

    return <div className="BasketProducts">{getProducts()}</div>
}

export default BasketProducts
