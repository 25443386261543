import React from 'react'
import { Button } from 'antd'
import './GlobErrorPage.css'

interface GlobErrorPageProps {
    error?: string
}

const GlobErrorPage = ({ error }: GlobErrorPageProps): JSX.Element => {
    return (
        <div className="GlobErrorPage">
            <h1 className="GlobErrorPageTitle">404</h1>
            <h2 className="GlobErrorPageTitle">Site Not Found!</h2>
            <h2 className="GlobErrorPageTitle">Error: {error}</h2>

            <Button
                type="primary"
                size="large"
                onClick={() => window.location.reload()}
                style={{ backgroundColor: 'var(--color-yellow)' }}
            >
                Try Again
            </Button>
        </div>
    )
}

export default GlobErrorPage
