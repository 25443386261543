import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form } from 'antd'
import { FormProps } from 'antd/lib'
import { AxiosError } from 'axios'
import {
    Address,
    useNotificationStateContext,
    User,
    useUserStateDispatch,
} from '../../contexts'
import { api } from '../../services'
import { SignUpForm } from '../../components'
import './RegistrationPage.css'


type RegistrationFieldType = {
    email?: string
    phone?: string
    userName?: string
    password?: string
    confirmPassword?: string
    telegram?: string
    city?: string
    street?: string
    house?: string
    flat?: string
    promoCode?: string
    comment?: string
}

type PromoCodeType = 'percent' | 'amount'

export interface PromoCode {
    value: string
    type: PromoCodeType
    discount: number
}

const RegistrationPage = () => {
    const [loading, setLoading] = useState(false)
    const [isExistUser, setIsExistUser] = useState(false)
    const [, setAddressId] = useState<string>()

    const userDispatch = useUserStateDispatch()
    const notificationState = useNotificationStateContext()
    const navigate = useNavigate()
    const { search } = useLocation()

    const [form] = Form.useForm()

    const { t } = useTranslation()

    const onRegistration: FormProps<RegistrationFieldType>['onFinish'] = async (
        values
    ) => {
        setLoading(true)
        setIsExistUser(false)

        try {
            const body: RegistrationFieldType = {
                email: values.email,
                phone: (values.phone || '').replace(/\D/g, ''),
                userName: values.userName,
                password: values.password,
                telegram: values.telegram,
                city: values.city,
                street: values.street,
                house: values.house,
                flat: values.flat,
                promoCode: values.promoCode
            }

            const response = await api.post<User>('/register', {
                ...body,
            })

            userDispatch({
                type: 'setUser',
                payload: response.data,
            })

            const responseAddress = await api.get('/addresses')

            userDispatch({
                type: 'setAddresses',
                payload: responseAddress.data.map((address: Address) => {
                    return {
                        value: address.id,
                        label: `${address.city}, ${address.street} ${address.house}${address.flat ? ', ' + t('authModal.flat') + ' ' + address.flat : ''}`,
                    }
                }),
            })

            notificationState.welcomeNotification(values.userName as string)

            navigate('/' + search)
        } catch (error) {
            if (error instanceof AxiosError) {
                if (error.response?.status === 409) {
                    setIsExistUser(true)
                    return
                }
            }

            notificationState.openErrorNotification({ description: `${error}` })
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const onChangeAddress = (value: string) => {
        setAddressId(value)
    }

    return (
        <div className="RegistrationPage">
            <Helmet>
                <link
                    rel="canonical"
                    href="https://coffochka.am/registration"
                />
            </Helmet>

            <h1 className="RegistrationPageTitle">
                {t('registrationPage.title')}
            </h1>

            <SignUpForm 
                form={form}
                loading={loading}
                onSubmit={onRegistration}
                onChangeAddress={onChangeAddress}
                footer={
                    () => (
                        <div className="SignUpFormFooter">
                            <Trans i18nKey="authModal.userAgreement">
                                <p className="UserAgreement">
                                    Нажимая кнопку Зарегистрироваться, Вы соглашаетесь c условиями
                                    <a href="user_agreement.pdf" target="_blank">
                                        пользовательского соглашения
                                    </a>
                                </p>
                            </Trans>

                            {isExistUser ? (
                                <p className="ErrorAuth ant-form-item-explain-error">
                                    {t('registrationPage.userExists')}
                                </p>
                            ) : null}
                        </div>
                    )
                }
            />
        </div>
    )
}

export default RegistrationPage
