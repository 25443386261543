import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import './FaqPage.css'

const FaqPage = () => {
    const { t } = useTranslation()

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://coffochka.am/faq" />
            </Helmet>

            <div className="FaqPage">
                <img
                    className="FaqPageImg"
                    src="coffochka4.webp"
                    alt="coffochka"
                />

                <div className="FaqPageText">
                    <h1>{t('faqPage.title')}</h1>

                    <p>{t('faqPage.text1')}</p>

                    <p>{t('faqPage.text2')}:</p>

                    <ol>
                        <Trans i18nKey="faqPage.text3">
                            <li>
                                Как оформить заказ? Вы можете заказ на сайте,
                                после чего мы свяжемся с вами по тем, контактам,
                                которые вы предоставили. Или можно написать нам
                                сообщение в директ{' '}
                                <a
                                    href="https://www.instagram.com/coffochka.am/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    инстаграм
                                </a>{' '}
                                и там бы обсудим все детали вашего заказа
                            </li>
                        </Trans>

                        <li>{t('faqPage.text4')}</li>

                        <Trans i18nKey="faqPage.text5">
                            <li>
                                Можно ли забрать заказ из точки-выдачи? Да, вы
                                можете выбрать такой вариант доставки. В этом
                                случае мы забронируем ваш заказ на 48 часов за
                                вами. Точка выдачи и наша кофейня расположены по
                                адресу{' '}
                                <a
                                    href="https://www.google.com/maps/place/Letters+and+Numbers+Coworking+%D0%A1offee+shop/@40.1862759,44.5090819,18.55z/data=!4m6!3m5!1s0x406abd1f1c40de35:0x6b8172edfb0fe273!8m2!3d40.1865782!4d44.5099022!16s%2Fg%2F11tmv54cj2?entry=ttu"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t('orderForm.pickupAddress')}
                                </a>
                            </li>
                        </Trans>

                        <li>{t('faqPage.text6')}</li>

                        <Trans i18nKey="faqPage.text7">
                            <li>
                                Можно ли оплатить со счета ИП или организации?
                                Да, напишите нам в{' '}
                                <a
                                    href="https://t.me/coffochka"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    телеграм
                                </a>{' '}
                                и мы предоставим вам реквизиты
                            </li>
                        </Trans>

                        <Trans i18nKey="faqPage.text8">
                            <li>
                                Нам нужен кофе для офиса или кафе, ресторана.
                                Напишите нам в{' '}
                                <a
                                    href="https://t.me/coffochka"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    телеграм
                                </a>{' '}
                                <a
                                    href="tel:+37493552175"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    или позвоните
                                </a>
                                . И мы обсудим поставку свежего кофе по
                                специальным условиям
                            </li>
                        </Trans>
                    </ol>
                </div>
            </div>
        </>
    )
}

export default FaqPage
