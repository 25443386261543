import React, { useRef } from 'react'
import { HeartFilled, SearchOutlined } from '@ant-design/icons'
import { useUserStateContext } from '../../contexts'
import i18n from '../../i18n'
import { Input, InputRef } from 'antd'

import './Filters.css'

interface FiltersProps {
    filters: number[]
    toggleFilters: (filter: number) => void
    searchFilter: string
    onChangeValueFilter: ({
        target,
    }: React.ChangeEvent<HTMLInputElement>) => void
}

const Filters = ({
    filters,
    toggleFilters,
    searchFilter,
    onChangeValueFilter,
}: FiltersProps) => {
    const userState = useUserStateContext()
    const searchFilterRef = useRef<InputRef>(null)

    const isActiveFilter = (filter: number) => {
        return filters.includes(filter)
    }

    const onClickToSearchFilter = () => {
        if (searchFilterRef.current) {
            searchFilterRef.current.focus()
        }
    }

    return (
        <div className="AppFilters">
            {userState.categories.map((category) => (
                <a
                    key={category.id}
                    className={
                        'AppFilter ' +
                        (isActiveFilter(category.id) ? 'AppFilterActive' : '')
                    }
                    href="#"
                    onClick={() => toggleFilters(category.id)}
                >
                    {i18n.language === 'ru' ? category.nameRu : category.nameEn}
                </a>
            ))}

            {userState.isAuthorized ? (
                <a
                    className={
                        'AppFilter ' +
                        (isActiveFilter(0) ? 'AppFilterActive' : '')
                    }
                    href="#"
                    onClick={() => toggleFilters(0)}
                >
                    <div className="AppFilterFavourite">
                        <HeartFilled />
                        {i18n.language === 'ru' ? 'Избанное' : 'Favourite'}
                    </div>
                </a>
            ) : null}

            {userState.categories.length && (
                <div className="AppFilter" onClick={onClickToSearchFilter}>
                    <Input
                        ref={searchFilterRef}
                        addonBefore={<SearchOutlined />}
                        variant="borderless"
                        size="large"
                        value={searchFilter}
                        onChange={onChangeValueFilter}
                        placeholder="Колумбия Уила"
                    />
                </div>
            )}
        </div>
    )
}

export default Filters
