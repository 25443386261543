import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import {
    BasketStateContext,
    UserStateContext,
    useUserStateDispatch,
} from './contexts'
import { getAddresses, router } from './services'
import './index.css'
import './i18n'

import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import { init } from './utils/init'
import { errorRouter } from './services/router'
import { AxiosError } from 'axios'
import { addressesToClientFormat, sortCategories } from './utils'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID ?? '',
}

TagManager.initialize(tagManagerArgs)

const App = () => {
    const [error, setError] = useState('')
    const userDispatch = useUserStateDispatch()
    const { t } = useTranslation()

    useEffect(() => {
        init()
            .then((res) => {
                const [user, categories, products] = res

                if (user.status === 'fulfilled') {
                    userDispatch({
                        type: 'setUser',
                        payload: user.value,
                    })

                    getAddresses()
                        .then((addresses) => {
                            userDispatch({
                                type: 'setAddresses',
                                payload: addressesToClientFormat(addresses),
                            })
                        })
                        .catch((err) => {
                            console.warn(err)
                        })
                }

                if (categories.status === 'fulfilled') {
                    userDispatch({
                        type: 'setCategories',
                        payload: sortCategories(categories.value),
                    })
                } else {
                    throw categories.reason
                }

                if (products.status === 'fulfilled') {
                    userDispatch({
                        type: 'setProducts',
                        payload: products.value,
                    })
                } else {
                    throw products.reason
                }

                TagManager.initialize({
                    ...tagManagerArgs,
                    events: {
                        initSite: 'init_site',
                    },
                })
            })
            .catch((error) => {
                if (error instanceof AxiosError) {
                    setError(error.message)
                } else {
                    setError('Unknown error')
                }

                TagManager.initialize({
                    ...tagManagerArgs,
                    events: {
                        initSite: 'init_site_error',
                    },
                })
            })
    }, [userDispatch, t])

    return <RouterProvider router={!error ? router : errorRouter(error)} />
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    <ConfigProvider
        theme={{
            token: {
                fontFamily: 'Roboto-Regular',
            },
        }}
    >
        <UserStateContext>
            <BasketStateContext>
                <App />
            </BasketStateContext>
        </UserStateContext>
    </ConfigProvider>
)
