import React, { useRef } from 'react'
import { Header as AntdHeader } from 'antd/es/layout/layout'
import { useLocation, useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { Dropdown, Select, Space, Button } from 'antd'
import { MenuProps } from 'antd/lib'
import { DownOutlined } from '@ant-design/icons'
import { BasketButton } from '../BasketButton'
import {
    useUserStateContext,
    useUserStateDispatch,
} from '../../contexts/userContext'
import {
    useAuthModalStateContext,
    useNotificationStateContext,
} from '../../contexts'
import { api } from '../../services'
import { phone, telegramLink, waLink } from '../../constants'
import './Header.css'

const headerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    color: '#fff',
    height: '100%',
    lineHeight: '64px',
    backgroundColor: 'var(--color-white)',
    padding: '0px',
}

const Header = () => {
    const userContext = useUserStateContext()
    const userDispatch = useUserStateDispatch()
    const navigate = useNavigate()
    const { search } = useLocation()
    const { t, i18n } = useTranslation()
    const useAuthModalContext = useAuthModalStateContext()
    const useNotificationContext = useNotificationStateContext()
    const ref = useRef(null)

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng)
    }

    const logout = () => {
        api.get('/logout')
            .then(() => {
                userDispatch({
                    type: 'deleteUser',
                })

                navigate('/' + search)
            })
            .catch((err) => {
                useNotificationContext.openErrorNotification({
                    description: `${err}`,
                })
            })
    }

    const items: MenuProps['items'] = [
        {
            label: <strong>{t('header.about')}</strong>,
            key: '0',
            onClick: () => navigate('/about' + search),
        },
        {
            type: 'divider',
        },
        {
            label: (
                <a href={telegramLink} target="_blank" rel="noreferrer">
                    {t('header.telegram')}
                </a>
            ),
            key: '1',
        },
        {
            label: (
                <a href={waLink} target="_blank" rel="noreferrer">
                    {t('header.whatsapp')}
                </a>
            ),
            key: '2',
        },
        {
            label: (
                <a href="tel:+37493552175" target="_blank" rel="noreferrer">
                    {phone}
                </a>
            ),
            key: '3',
        },
        {
            type: 'divider',
        },
        {
            label: <strong>{t('header.faq')}</strong>,
            key: '4',
            onClick: () => navigate('/faq' + search),
        },
    ]

    const items2: MenuProps['items'] = [
        {
            label: (
                <a
                    rel="noreferrer"
                    onClick={() => navigate('/account' + search)}
                >
                    {t('header.account')}
                </a>
            ),
            key: '0',
        },
        {
            label: (
                <a rel="noreferrer" onClick={logout}>
                    {t('header.logout')}
                </a>
            ),
            key: '1',
            danger: true,
        },
    ]

    const localeOptions = [
        {
            label: '🇬🇧',
            value: 'en',
            desc: '🇬🇧',
        },
        {
            label: '🇦🇲',
            value: 'am',
            desc: '🇦🇲',
        },
        {
            label: '🇷🇺',
            value: 'ru',
            desc: '🇷🇺',
        },
    ]

    return (
        <AntdHeader ref={ref} style={headerStyle} className="Header">
            <div className="HeaderWrapper">
                <div className="HeaderUpper">
                    <div className="HeaderUpperWrapper">
                        <div className="HeaderUpperWrapperLeft">
                            <Select
                                className="HeaderContact"
                                variant="borderless"
                                size="large"
                                placeholder="select one country"
                                defaultValue={i18n.language}
                                onChange={changeLanguage}
                                options={localeOptions}
                            />

                            <Dropdown menu={{ items }} trigger={['click']}>
                                <a onClick={(e) => e.preventDefault()}>
                                    <Space>
                                        {t('header.contacts')}
                                        <DownOutlined />
                                    </Space>
                                </a>
                            </Dropdown>
                        </div>

                        <div className="HeaderUpperWrapperRight">
                            <span className="HeaderUpperInfo">
                                <strong>
                                    <a
                                        className="HeaderUpperInfoLink"
                                        href="tel:+37493552175"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        +374 93 552-175
                                    </a>
                                </strong>{' '}
                                <span className="HeaderUpperInfoTime">
                                    ({t('header.time')})
                                </span>
                            </span>

                            <div className="HeaderUpperAuthBlock">
                                {!userContext.isAuthorized ? (
                                    <div className="BasketLogin">
                                        <Button
                                            type="link"
                                            className="BasketButtonLink"
                                            onClick={() =>
                                                useAuthModalContext.open()
                                            }
                                        >
                                            {t('header.login')}
                                        </Button>

                                        <Button
                                            type="link"
                                            className="BasketButtonLink"
                                            onClick={() =>
                                                navigate(
                                                    '/registration' + search
                                                )
                                            }
                                        >
                                            {t('header.registration')}
                                        </Button>
                                    </div>
                                ) : (
                                    <Dropdown
                                        menu={{ items: items2 }}
                                        trigger={['click']}
                                    >
                                        <a onClick={(e) => e.preventDefault()}>
                                            <Space>
                                                {userContext.user?.userName}
                                                <DownOutlined />
                                            </Space>
                                        </a>
                                    </Dropdown>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="HeaderLower">
                    <div className="HeaderLowerWrapper">
                        <div className="HeaderLeftSide">
                            <div
                                className="HeaderTitle"
                                onClick={() => navigate('/' + search)}
                            >
                                <img
                                    className="HeaderTitleIcon"
                                    src="Layer1065.png"
                                    width="100px"
                                    alt="logo"
                                />
                                <div className="HeaderTitles">
                                    <span>{t('header.logo.text1')}</span>
                                    <Trans i18nKey="header.logo.text2">
                                        <span>
                                            0
                                            <span className="HeaderTitlesText_red">
                                                1
                                            </span>
                                        </span>
                                    </Trans>
                                </div>
                            </div>
                        </div>

                        <div className="HeaderRightSide">
                            <BasketButton />
                        </div>
                    </div>
                </div>
            </div>
        </AntdHeader>
    )
}

export default Header
