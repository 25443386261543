import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Popover } from 'antd'
import { ShoppingOutlined } from '@ant-design/icons'
import { useBasketStateContext, useUserStateContext } from '../../contexts'
import { BasketProducts } from '../BasketProducts'
import { isEmptyObject } from '../../utils'
import './BasketButton.css'

const BasketButton = () => {
    const navigate = useNavigate()
    const { search } = useLocation()
    const { t } = useTranslation()
    const userState = useUserStateContext()
    const basketContext = useBasketStateContext()

    const [open, setOpen] = useState(false)

    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen)
    }

    const toBasket = () => {
        setOpen(false)
        navigate('/basket' + search)
    }

    const calcTotalSum = () => {
        return Object.keys(basketContext.products).reduce((acc, key) => {
            if (userState.products) {
                acc +=
                    basketContext.products[key].count *
                    userState.products[key].price
            }

            return acc
        }, 0)
    }

    const calcCountOfProducts = () => {
        return Object.keys(basketContext.products).reduce(
            (acc, key) => acc + basketContext.products[key].count,
            0
        )
    }

    return (
        <div className="BasketButtonWrapper">
            <Popover
                className="BasketButtonPopover"
                content={
                    <div className="BasketButtonPopoverContent">
                        {!isEmptyObject(basketContext.products) ? (
                            <>
                                <h1 className="BasketButtonPopoverTitle">
                                    {t('basketButton.title')}
                                </h1>

                                <BasketProducts
                                    products={basketContext.products}
                                />

                                <div className="BasketButtonPopoverControl">
                                    <Button
                                        icon={<ShoppingOutlined />}
                                        type="primary"
                                        className="BasketButtonPopoverButton"
                                        shape="round"
                                        onClick={toBasket}
                                        size={'large'}
                                    >
                                        {t('basketButton.button')}
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <div>{t('basketButton.emptyBasketText')}</div>
                        )}
                    </div>
                }
                open={open}
                onOpenChange={handleOpenChange}
            >
                <Button
                    style={{
                        width: '35px',
                        height: '35px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    className={
                        basketContext.products.length
                            ? 'BasketButton_active'
                            : 'BasketButton'
                    }
                    type="primary"
                    shape="round"
                    size="small"
                    onClick={toBasket}
                >
                    <ShoppingOutlined size={40} />
                </Button>
            </Popover>
            <div className="BasketButtonCalc">
                <span>{calcTotalSum()} ֏</span>
                <span>
                    {t('basketButton.countText', {
                        count: calcCountOfProducts(),
                    })}
                </span>
            </div>
        </div>
    )
}

export default BasketButton
