export function throttle<
    T extends (...args: Parameters<T>) => Promise<ReturnType<T>>,
>(
    func: T,
    limit: number
): (...args: Parameters<T>) => Promise<ReturnType<T> | void> {
    let lastCall = 0
    let timeout: NodeJS.Timeout | null = null

    return (...args: Parameters<T>): Promise<ReturnType<T> | void> => {
        return new Promise((resolve) => {
            const now = Date.now()

            if (timeout) {
                clearTimeout(timeout)
            }

            if (now - lastCall >= limit) {
                lastCall = now
                resolve(func(...args))
            } else {
                timeout = setTimeout(
                    () => {
                        lastCall = Date.now()
                        resolve(func(...args))
                    },
                    limit - (now - lastCall)
                )
            }
        })
    }
}

export function debounceAsync<
    T extends (...args: Parameters<T>) => Promise<ReturnType<T> | void>,
>(
    func: T,
    wait: number
): (...args: Parameters<T>) => Promise<ReturnType<T> | void> {
    let timeout: NodeJS.Timeout | null = null
    let resolveQueue: ((value: ReturnType<T> | void) => void)[] = []

    return (...args: Parameters<T>): Promise<ReturnType<T> | void> => {
        return new Promise((resolve) => {
            if (timeout) {
                clearTimeout(timeout)
            }

            // Сохранить текущий `resolve` для вызова позже
            resolveQueue.push(resolve)

            timeout = setTimeout(async () => {
                timeout = null
                const result = await func(...args)
                // Разрешить все ожидающие промисы
                resolveQueue.forEach((r) => r(result))
                resolveQueue = []
            }, wait)
        })
    }
}
