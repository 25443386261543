import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card as AntdCard, Button, Skeleton, Tag, Typography } from 'antd'
import {
    DownloadOutlined,
    ArrowDownOutlined,
    ArrowUpOutlined,
} from '@ant-design/icons'
import { Count } from '../Count'
import {
    Product,
    useAuthModalStateContext,
    useBasketStateDispatch,
    useNotificationStateContext,
    useUserStateContext,
    useUserStateDispatch,
} from '../../contexts'
import { Favorite } from '../Favorite'
import i18n from '../../i18n'
import './Card.css'
import { AxiosResponse } from 'axios'

interface CardProps extends Product {
    updateFavoriteProducts: (
        favoriteProducts: number[]
    ) => Promise<AxiosResponse<{ favoriteProducts: number[] }>>
    onClick?: () => void
}

const Card = ({
    id,
    itemName,
    description,
    price,
    imagePath,
    balance,
    category,
    onClick,
    updateFavoriteProducts,
}: CardProps) => {
    const [count, setCount] = useState(1)
    const [loading, setLoading] = useState(true)
    const [favorite, setFavorite] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const { t } = useTranslation()
    const dispatch = useBasketStateDispatch()
    const userDispatch = useUserStateDispatch()
    const userState = useUserStateContext()
    const notificationState = useNotificationStateContext()
    const useAuthModalContext = useAuthModalStateContext()

    useEffect(() => {
        setFavorite((userState.user?.favoriteProducts || []).includes(id))
    }, [id, userState.user])

    const handleImageLoaded = () => {
        setLoading(false)
    }

    const setCountHandler = (count: number) => {
        if (count <= balance) {
            setCount(count)
        }
    }

    const toggleFavorite = async () => {
        if (!userState.isAuthorized) {
            useAuthModalContext.open()
            return
        }

        setFavorite(!favorite)

        const favoriteProducts = !favorite
            ? [...(userState.user?.favoriteProducts as []), id]
            : (userState.user?.favoriteProducts as []).filter((f) => f !== id)

        userDispatch({
            type: 'setFavoriteProducts',
            payload: favoriteProducts,
        })

        if (!favorite) {
            notificationState.setFavoriteProductNotification()
        }

        updateFavoriteProducts(favoriteProducts)
            .then((response) => {
                userDispatch({
                    type: 'setFavoriteProducts',
                    payload: response.data.favoriteProducts,
                })
            })
            .catch(function (error) {
                notificationState.openErrorNotification({
                    description: `${error}`,
                })
                console.log(error)
                setFavorite(favorite)
            })
    }

    const addProduct = () => {
        dispatch({
            type: 'addProduct',
            payload: {
                id,
                count,
            },
        })

        if (onClick) {
            onClick()
        }
    }

    const getCategoryName = (categoryId: number) => {
        const category = userState.categories.find(
            (category) => category.id === +categoryId
        )

        if (category) {
            return i18n.language === 'ru' ? category!.nameRu : category!.nameEn
        }

        return ''
    }

    return (
        <AntdCard
            className="Card"
            styles={{
                body: {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                    padding: '24px',
                    color: 'var(--color-black)',
                },
            }}
        >
            <div className="CardHeader">
                {loading ? (
                    <Skeleton.Input
                        active={true}
                        className="CardSkeletonInput"
                        size="small"
                    />
                ) : (
                    <div className="CardHeaderWrapper">
                        <Tag color="orange">{getCategoryName(category)}</Tag>
                        <Favorite active={favorite} onClick={toggleFavorite} />
                    </div>
                )}
            </div>

            {loading ? (
                <Skeleton.Input
                    className="CardSkeletonInput"
                    size={'large'}
                    active={true}
                />
            ) : (
                <h3 className="CardTitle">{itemName}</h3>
            )}

            <div className="CardImgContainer">
                {loading && (
                    <Skeleton.Image
                        active={loading}
                        className="CardSkeletonImg"
                    />
                )}
                <img
                    className="CardImg"
                    style={{ display: !loading ? 'block' : 'none' }}
                    alt="example"
                    src={imagePath ?? 'random_coffee_img.png'}
                    onLoad={handleImageLoaded.bind(this)}
                />
            </div>

            {loading ? (
                <>
                    <Skeleton.Input className="CardSkeletonInput" />
                    <Skeleton.Input className="CardSkeletonInput" />
                </>
            ) : (
                <Typography.Paragraph
                    style={{
                        minHeight: '20px',
                        height: '100%',
                        textAlign: 'left',
                    }}
                    ellipsis={{
                        rows: 2,
                        expandable: 'collapsible',
                        symbol: () =>
                            expanded ? (
                                <ArrowUpOutlined
                                    style={{
                                        color: 'var(--color-yellow)',
                                    }}
                                />
                            ) : (
                                <ArrowDownOutlined
                                    style={{
                                        color: 'var(--color-yellow)',
                                    }}
                                />
                            ),
                        expanded,
                        onExpand: (_, info) => setExpanded(info.expanded),
                    }}
                >
                    {description}
                </Typography.Paragraph>
            )}

            <div className="CardFooter">
                <div className="CardPriceBlock">
                    {loading ? (
                        <Skeleton.Input size={'small'} active={true} />
                    ) : (
                        <p className="CardPrice">{price} ֏</p>
                    )}
                </div>
                <div className="CardControls">
                    {loading ? (
                        <Skeleton.Input size={'small'} active={true} />
                    ) : (
                        <Count value={count} setCount={setCountHandler} />
                    )}

                    {loading ? (
                        <Skeleton.Button
                            active={true}
                            size="large"
                            shape="round"
                        />
                    ) : balance > 0 ? (
                        <Button
                            className="CardButton"
                            disabled={balance <= 0}
                            type="primary"
                            shape="round"
                            icon={<DownloadOutlined />}
                            size={'large'}
                            style={{ backgroundColor: 'var(--color-yellow)' }}
                            onClick={addProduct}
                        >
                            {t('card.buyButton')}
                        </Button>
                    ) : (
                        <div className="CardButtonIsNotExists">
                            Нет в наличии
                        </div>
                    )}
                </div>
            </div>
        </AntdCard>
    )
}

export default Card
