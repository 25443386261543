import React, { useContext, useReducer, createContext } from 'react'
import { PromoCode } from '../pages/RegistrationPage/RegistrationPage'

type Action =
    | {
          type: 'setUser'
          payload: User
      }
    | {
          type: 'deleteUser'
      }
    | {
          type: 'setAddresses'
          payload: {
              label: string
              value: string
          }[]
      }
    | {
          type: 'setOrders'
          payload: Order[]
      }
    | {
          type: 'setProducts'
          payload: Products
      }
    | {
          type: 'setCategories'
          payload: Category[]
      }
    | {
          type: 'setPromoCode'
          payload: PromoCode
      }
    | {
          type: 'setFavoriteProducts'
          payload: number[]
      }

export type Dispatch = (action: Action) => void

export interface User {
    email?: string
    id?: string
    phone?: string
    userName?: string
    addressId?: string
    telegram?: string
    favoriteProducts: number[]
    promoCode?: PromoCode | null
}

export interface Address {
    id: string
    userId: string
    city: string
    street: string
    house: string
    flat: string
    createdAt: string
    comment: string
}

interface OrderProduct {
    id: number
    count: number
}

export interface Order {
    id: string
    userId: string
    status: string
    operator: string
    discount: string
    deliveryMethod: string
    products: OrderProduct[]
    addressId: string
    comment: string
    createdAt: string
    updatedAt: number
    finishedAt: number
}

export interface Product {
    id: number
    imagePath: string
    itemName: string
    price: number
    category: number
    description: string
    balance: number
}

export interface Category {
    sort: number
    deleted: boolean
    additional: string
    id: number
    nameRu: string
    metaDescEn: string
    metaTitleRu: string
    extraCategories: string
    recomended: string
    shortUrlEn: string
    metaKeyEn: string
    metaDescRu: string
    logo: string
    shortUrlRu: string
    metaDesc: string
    metaKeyRu: string
    catId: number
    name: string
    nameEn: string
    parentId: number
    level: number
    shortUrl: string
    metaKey: string
    image: string
    metaTitle: string
    metaTitleEn: string
    active: boolean
    isTop: number
}

export interface Products {
    [key: string]: Product
}

export interface UserAddress { 
    label: string; 
    value: string;
}

export interface UserState extends User {
    isAuthorized: boolean
    user?: User
    addresses: UserAddress[]
    orders: Order[]
    products?: Products
    categories: Category[]
}

// TODO https://www.geeksforgeeks.org/strategy-method-javascript-design-pattern/
const createInitialState = (initParams?: Partial<UserState>): UserState => ({
    categories: [],
    isAuthorized: false,
    favoriteProducts: [],
    addresses: [],
    orders: [],
    ...initParams,
})

const UserStateContextProvider = createContext<UserState | undefined>(undefined)
const UserStateDispatchContext = createContext<Dispatch | undefined>(undefined)

export const useUserStateContext = () => {
    const context = useContext(UserStateContextProvider)
    if (context === undefined) {
        throw new Error(
            'useUserStateContext must be used within a useUserStateContextProvider'
        )
    }
    return context
}

export const useUserStateDispatch = (): Dispatch => {
    const context = useContext(UserStateDispatchContext)

    if (context === undefined) {
        throw new Error(
            'useUserStateDispatch must be used within UserStateDispatchContext'
        )
    }
    return context
}

const appStateReducer = (state: UserState, action: Action): UserState => {
    switch (action.type) {
        case 'setUser':
            return {
                ...state,
                user: action.payload,
                isAuthorized: true,
            }
        case 'deleteUser':
            return {
                ...state,
                user: undefined,
                addresses: [],
                orders: [],
                isAuthorized: false,
            }
        case 'setAddresses':
            return {
                ...state,
                addresses: action.payload,
            }
        case 'setOrders':
            return {
                ...state,
                orders: action.payload,
            }
        case 'setProducts':
            return {
                ...state,
                products: action.payload,
            }
        case 'setCategories':
            return {
                ...state,
                categories: action.payload,
            }
        case 'setPromoCode':
            return {
                ...state,
                user: {
                    ...state.user,
                    promoCode: action.payload,
                    favoriteProducts: state.user?.favoriteProducts || []
                },
            }
        case 'setFavoriteProducts':
            return {
                ...state,
                user: {
                    ...state.user,
                    favoriteProducts: action.payload,
                },
            }
        default:
            return state
    }
}

export interface IUserStateProviderProps {
    initialState?: UserState | undefined
}

export const UserStateContext: React.FC<
    React.PropsWithChildren<IUserStateProviderProps>
> = ({ initialState, children }) => {
    const [state, dispatch] = useReducer(
        appStateReducer,
        createInitialState(initialState)
    )

    return (
        <UserStateContextProvider.Provider value={state}>
            <UserStateDispatchContext.Provider value={dispatch}>
                {children}
            </UserStateDispatchContext.Provider>
        </UserStateContextProvider.Provider>
    )
}
