import React from 'react'
import { Button, Divider } from 'antd'
import { Product, useBasketStateDispatch } from '../../contexts'
import { Count } from '../Count'
import { DeleteOutlined } from '@ant-design/icons'
import './BasketProduct.css'

export interface BasketProductProps {
    count: number
    product: Product
    imgSize?: string
}

const BasketProduct = ({ product, imgSize, count }: BasketProductProps) => {
    const dispatch = useBasketStateDispatch()

    const removeProduct = () => {
        dispatch({
            type: 'removeProduct',
            payload: product.id,
        })
    }

    const setCount = (count: number) => {
        if (count <= product.balance) {
            if (count < 1) {
                return
            }

            dispatch({
                type: 'updateProduct',
                payload: {
                    id: product.id,
                    count,
                },
            })
        }
    }

    return (
        <>
            <div className="BasketProduct">
                <img
                    alt="example"
                    width={imgSize ? imgSize : '50px'}
                    height="100%"
                    src={product.imagePath ?? 'random_coffee_img.png'}
                />

                <div className="BasketProductContent">
                    <h1 className="BasketProductTitle">{product.itemName}</h1>
                    <span className="BasketProductPrice">
                        {count}x{product.price} ֏
                    </span>
                </div>

                <div className="BasketProductControls">
                    <Count value={count} setCount={setCount} />
                    <Button
                        className="BasketProductRemoveBtn"
                        type="link"
                        color="gray"
                        icon={<DeleteOutlined />}
                        size={'small'}
                        onClick={removeProduct}
                    />
                </div>
            </div>
            <Divider />
        </>
    )
}

export default BasketProduct
