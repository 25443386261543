import React, { ReactElement } from 'react'
import { MaskedInput } from 'antd-mask-input'
import { useTranslation } from 'react-i18next'
import {
    Button,
    Col,
    Form,
    FormInstance,
    Input,
    Row,
} from 'antd'
import { useUserStateContext } from '../../contexts'
import { AddressForm } from '../AddressForm'
import { RegistrationFieldType } from '../../pages/AccountPage/AccountPage'

import { AxiosError } from 'axios'
import { api } from '../../services'

import './SignUpForm.css'

type SignUpFormFieldType = {
    email?: string
    phone?: string
    telegram?: string
    userName?: string
    addressId?: string
    password?: string
    confirmPassword?: string
    city?: string
    street?: string
    house?: string
    flat?: string
    promoCode?: string
}

export interface PromoCode {
    value: string
    type: 'percent' | 'amount'
    discount: number
}


interface SignUpFormProps {
    form: FormInstance
    loading: boolean
    onSubmit: (values: RegistrationFieldType) => void
    onChangeAddress: (value: string) => void
    footer?: () => ReactElement<HTMLDivElement>
}

const getPromoCode = async (promoCode: string) => {
    try {
        const response = await api.get<PromoCode>(
            `/promo?promo=${promoCode}`
        )
        return response.data
    } catch (err) {
        if (err instanceof AxiosError && err.response?.status === 404) {
            return null
        }

        throw err
    }
}

const colProps = {
    xs: { flex: '100%' },
    sm: { flex: '100%' },
    md: { flex: '100%' },
    lg: { flex: '50%' },
    xl: { flex: '50%' },
}

const SignUpForm = ({
    form,
    loading,
    onSubmit,
    onChangeAddress,
    footer
}: SignUpFormProps) => {
    const { t } = useTranslation()
    const userState = useUserStateContext()

    const onHandleSubmit = (values: SignUpFormFieldType) => {
        const form = {
            ...values,
    }
        onSubmit(form)
    }

    return (
        <Form
            form={form}
            name="basic"
            layout="vertical"
            size="large"
            style={{ width: '100%' }}
            onFinish={onHandleSubmit}
            autoComplete="off"
            disabled={loading}
        >
            <Row gutter={[24, 0]}>
                <Col {...colProps}>
                    <Form.Item<SignUpFormFieldType>
                        label={t('orderForm.userName')}
                        name="userName"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your User name!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                

                <Col {...colProps}>
                    <Form.Item<SignUpFormFieldType>
                        label={t('orderForm.phone')}
                        name="phone"
                        rules={[
                            { required: true, message: 'Please input your phone!' },
                        ]}
                    >
                        <MaskedInput
                            placeholder={t('orderForm.phone')}
                            size="large"
                            mask={'+374(00)000-000'}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[24, 0]}>
                <Col {...colProps}>
                    <Form.Item<SignUpFormFieldType>
                        label="Email"
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                required: true,
                                message: 'Please input your email!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>

                <Col {...colProps}>
                    <Form.Item<SignUpFormFieldType> label="Telegram" name="telegram">
                        <Input addonBefore="@" />
                    </Form.Item>
                </Col>
            </Row>


            <Row gutter={[24, 0]}>
                <Col span={24}> 
                    <AddressForm
                        value={userState.user?.addressId}
                        options={userState.addresses}
                        onChange={onChangeAddress}
                    />
                </Col>
            </Row>

            <Row gutter={[24, 0]}>
                <Col {...colProps}>
                    <Form.Item<SignUpFormFieldType>
                        label={t('orderForm.password')}
                        name="password"
                    >
                        <Input.Password />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item<SignUpFormFieldType>
                        label={t('orderForm.confirmPassword')}
                        name="confirmPassword"
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(
                                        new Error(
                                            t('orderForm.passwordValidationMessage')
                                        )
                                    )
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[24, 0]}>
                <Col {...colProps}>
                    <Form.Item<SignUpFormFieldType>
                        label="Promo code"
                        name="promoCode"
                        validateDebounce={2000}
                        hasFeedback
                        rules={[
                            () => ({
                                async validator(_, value) {
                                    if (!value) {
                                        return Promise.resolve()
                                    } else {
                                        try {
                                           const promoCode = await getPromoCode(value)

                                           if (!promoCode) {
                                                return Promise.reject(
                                                    new Error(
                                                        t('registrationPage.invalidPromoCode')
                                                    )
                                                )
                                           }

                                           return Promise.resolve()
                                        } catch(err) {
                                            console.log(err)
                                            return Promise.reject(
                                                new Error(
                                                    t('registrationPage.invalidPromoCode')
                                                )
                                            )
                                        }
                                    }
                                },
                            }),
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[24, 0]} justify="center">
                <Col span={7}>
                    <Form.Item>
                        <Button
                            disabled={loading}
                            className="SubmitBtn"
                            type="primary"
                            shape="round"
                            size={'large'}
                            style={{
                                backgroundColor: 'var(--color-yellow)',
                                width: '100%',
                            }}
                            htmlType="submit"
                        >
                            {t('accountPage.saveButton')}
                        </Button>
                    </Form.Item>
                </Col>
                {footer ? footer() : null}
            </Row>
        </Form>
    )
}

export default SignUpForm
