import React, { useContext, createContext, useState } from 'react'
import { AuthorizationModal } from '../components/AuthModal'

export interface AuthModalState {
    open: () => void
    close: () => void
}

const AuthModalStateContextProvider = createContext<AuthModalState | undefined>(
    undefined
)

export const useAuthModalStateContext = (): AuthModalState => {
    const context = useContext(AuthModalStateContextProvider)
    if (context === undefined) {
        throw new Error(
            'useAuthModalContext must be used within a useAuthModalStateContextProvider'
        )
    }
    return context
}

export const AuthModalProvider: React.FC<React.PropsWithChildren> = ({
    children,
}: React.PropsWithChildren) => {
    const [modal2Open, setModal2Open] = useState(false)

    const open = () => {
        setModal2Open(true)
    }
    const close = () => {
        setModal2Open(false)
    }

    return (
        <AuthModalStateContextProvider.Provider value={{ open, close }}>
            {children}
            <AuthorizationModal open={modal2Open} setOpen={setModal2Open} />
        </AuthModalStateContextProvider.Provider>
    )
}
