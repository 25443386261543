import React, { useCallback, useEffect } from 'react'
import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { TableProps } from 'antd/lib'
import {
    Order,
    useUserStateContext,
    useUserStateDispatch,
} from '../../contexts'
import { clientDateFormat, ordersToMap } from '../../utils'
import { getOrders } from '../../services'

import './OrdersTable.css'

const OrdersTable = () => {
    const userState = useUserStateContext()
    const userDispatch = useUserStateDispatch()
    const { t } = useTranslation()

    const getAddressById = (addressId: string) => {
        const address = userState.addresses?.find(
            (address) => address.value === addressId
        )

        if (!address) {
            return undefined
        }

        return `${address.label}`
    }

    const getProductById = (productId: number) => {
        return userState.products![productId]
    }

    const columns: TableProps<Order>['columns'] = [
        {
            title: t('accountPage.orderId'),
            dataIndex: 'externalId',
            key: 'externalId',
        },
        {
            title: t('accountPage.deliveryTime'),
            dataIndex: 'deliveryTime',
            key: 'deliveryTime',
            render: (deliveryTime) => (
                <div>{clientDateFormat(deliveryTime)}</div>
            ),
        },
        {
            title: t('accountPage.status'),
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: t('accountPage.deliveryMethod'),
            dataIndex: 'deliveryMethod',
            key: 'deliveryMethod',
        },
        {
            title: t('accountPage.address'),
            dataIndex: 'address',
            key: 'address',
            render: (address) => <div>{getAddressById(address.id)}</div>,
        },
        {
            title: t('accountPage.createdAt'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => <div>{clientDateFormat(createdAt)}</div>,
        },
        {
            title: t('accountPage.comment'),
            dataIndex: 'comment',
            key: 'comment',
        },
    ]

    const getOrdersCallback = useCallback(async () => {
        try {
            const orders = await getOrders()

            userDispatch({
                type: 'setOrders',
                payload: ordersToMap(orders),
            })
        } catch (err) {
            console.warn(err)
        }
    }, [userDispatch])

    useEffect(() => {
        getOrdersCallback()
    }, [getOrdersCallback])

    return (
        <Table
            columns={columns}
            dataSource={userState.orders}
            expandable={{
                expandedRowRender: (record) => (
                    <p style={{ margin: 0 }}>
                        {record.products.map((product) => (
                            <div key={product.id}>
                                {product.count} -{' '}
                                {getProductById(product.id).itemName}
                            </div>
                        ))}
                    </p>
                ),
            }}
            rowSelection={{}}
        />
    )
}

export default OrdersTable
