import React from 'react'
import { createBrowserRouter, RouteObject } from 'react-router-dom'
import {
    BasketPage,
    FaqPage,
    HomePage,
    AboutPage,
    NotFoundPage,
    AccountPage,
    RegistrationPage,
    GlobErrorPage,
} from '../pages'

import { Layout } from '../components'

export const router = createBrowserRouter([
    {
        element: <Layout />,
        children: [
            {
                path: '/',
                element: <HomePage />,
            },
            {
                path: '/basket',
                element: <BasketPage />,
            },
            {
                path: '/faq',
                element: <FaqPage />,
            },
            {
                path: '/about',
                element: <AboutPage />,
            },
            {
                path: '/account',
                element: <AccountPage />,
            },
            {
                path: '/registration',
                element: <RegistrationPage />,
            },
            {
                path: '*',
                element: <NotFoundPage />,
            },
        ] as RouteObject[],
    },
])

export const errorRouter = (error: string) =>
    createBrowserRouter([
        {
            element: <Layout />,
            children: [
                {
                    path: '*',
                    element: <GlobErrorPage error={error} />,
                },
            ] as RouteObject[],
        },
    ])
