import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { notification } from 'antd'
import { Cards, Filters } from '../../components'
import {
    useNotificationStateContext,
    useUserStateContext,
} from '../../contexts'
import './HomePage.css'

const HomePage = () => {
    const [searchFilter, setSearchFilter] = useState('')

    const [filters, setFilters] = useState<number[]>([])
    const userState = useUserStateContext()
    const [, contextHolder] = notification.useNotification()
    const notificationState = useNotificationStateContext()

    const openNotification = () => {
        notificationState.addToBaskeNotification()
    }

    const onChangeValueFilter = ({
        target,
    }: React.ChangeEvent<HTMLInputElement>) => {
        setSearchFilter(target.value)
    }

    const toggleFilters = (filter: number) => {
        if (filters.includes(filter)) {
            setFilters(filters.filter((f) => f !== filter))
        } else {
            setFilters([...filters, filter])
        }
    }

    useEffect(() => {
        setFilters([])
    }, [userState.isAuthorized])

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://coffochka.am" />
            </Helmet>

            {contextHolder}

            <div className="HomePage">
                <Filters
                    filters={filters}
                    toggleFilters={toggleFilters}
                    searchFilter={searchFilter}
                    onChangeValueFilter={onChangeValueFilter}
                />

                {userState.products && (
                    <Cards
                        products={userState.products}
                        filters={filters}
                        searchFilter={searchFilter}
                        onClick={openNotification}
                    />
                )}
            </div>
        </>
    )
}

export default HomePage
