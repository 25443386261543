import React from 'react'
import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next'
import { mapLink, telegramLink } from '../../constants'
import './AboutPage.css'

const AboutPage = () => {
    const { t } = useTranslation()

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://coffochka.am/about" />
            </Helmet>

            <div className="AboutPage">
                <img
                    className="AboutPageImg"
                    src="coffochka4.webp"
                    alt="coffochka"
                />
                <div className="AboutPageText">
                    <h1>{t('aboutPage.title')}</h1>
                    <p>
                        <strong>{t('aboutPage.text0')}</strong>
                    </p>
                    <p>{t('aboutPage.text1')}</p>

                    <Trans i18nKey="aboutPage.text2">
                        <p>
                            Весь кофе в пачках представлен на нашем сайте, а в
                            нашей кофейне и
                            <a href={mapLink} target="_blank" rel="noreferrer">
                                точке выдачи заказов
                            </a>
                            вы также можете попробовать кофе. До встречи!
                        </p>
                    </Trans>

                    <h2>{t('aboutPage.text3')}</h2>

                    <Trans i18nKey="aboutPage.text4">
                        <p>
                            Мы понимаем, как важно иметь отличный кофе в офисе
                            или ресторане. Поэтому мы готовы обеспечить вас
                            свежеобжаренным кофе на регулярной основе.
                            Независимо от того, нужен ли вам кофе для утреннего
                            бодрствования сотрудников или для создания уютной
                            атмосферы в кафе, мы готовы подобрать оптимальное
                            решение.{' '}
                            <a
                                href={telegramLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Напишите нам
                            </a>
                        </p>
                    </Trans>
                </div>
            </div>
        </>
    )
}

export default AboutPage
