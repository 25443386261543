import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Button, Col, Form, Radio, Row } from 'antd'
import { ShoppingOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { OrderForm } from '../../components'
import { BasketProducts } from '../../components/BasketProducts'
import {
    useBasketStateContext,
    useBasketStateDispatch,
    useNotificationStateContext,
} from '../../contexts'
import {
    Address,
    useUserStateContext,
    useUserStateDispatch,
} from '../../contexts/userContext'
import { api } from '../../services'
import { isEmptyObject, mapToArray } from '../../utils'
import { RadioChangeEvent } from 'antd/lib'
import { AxiosError } from 'axios'
import './BasketPage.css'

const BasketPage = () => {
    const navigate = useNavigate()
    const { search } = useLocation()
    const { t } = useTranslation()

    const [form] = Form.useForm()
    const [form2] = Form.useForm()

    const [loading, setLoading] = useState(false)
    const [isExistUser, setIsExistUser] = useState(false)

    const basketContext = useBasketStateContext()
    const basketDispatch = useBasketStateDispatch()
    const notificationState = useNotificationStateContext()
    const userState = useUserStateContext()
    const userDispatch = useUserStateDispatch()

    const onFinish = async () => {
        try {
            await Promise.all([form.validateFields(), form2.validateFields()])

            setLoading(true)

            const {
                city,
                street,
                house,
                flat,
                addressId,
                deliveryMethod,
                email,
                phone,
                userName,
                password,
                deliveryTime,
                comment,
                telegram,
            } = form.getFieldsValue()

            const query = new URLSearchParams(search)
            const paramSource = query.get('source')

            const values = {
                city,
                street,
                house,
                flat,
                addressId,
                deliveryMethod,
                email,
                phone,
                userName,
                deliveryTime: dayjs(deliveryTime).unix(),
                products: mapToArray(basketContext.products),
                comment,
                telegram,
                source: paramSource || '',
            }

            if (isRegister) {
                setIsExistUser(false)

                try {
                    const response = await api.post('/register', {
                        email: values.email,
                        phone: (values.phone || '').replace(/\D/g, ''),
                        userName: values.userName,
                        password,
                        source: values.source,
                        telegram: values.telegram,
                    })

                    userDispatch({
                        type: 'setUser',
                        payload: response.data,
                    })

                    notificationState.welcomeNotification(
                        values.userName as string
                    )
                } catch (error) {
                    notificationState.openErrorNotification({
                        description: `${error}`,
                    })
                    console.log(error)

                    setLoading(false)

                    if (error instanceof AxiosError) {
                        if (error.response?.status === 409) {
                            setIsExistUser(true)
                            return
                        }
                    }

                    return
                }
            }

            try {
                await api.post('/order', {
                    ...values,
                    phone: values.phone.replace(/\D/g, ''),
                    promoCode: userState.user?.promoCode?.value || '',
                })

                notificationState.submitOrderNotification()
                basketDispatch({
                    type: 'clearBasket',
                })
                navigate('/' + search)
            } catch (error) {
                notificationState.openErrorNotification({
                    description: `${error}`,
                })
                console.log(error)
            } finally {
                setLoading(false)
            }

            if (isRegister) {
                try {
                    const responseAddress = await api.get('/addresses')

                    userDispatch({
                        type: 'setAddresses',
                        payload: responseAddress.data.map(
                            (address: Address) => {
                                return {
                                    value: address.id,
                                    label: `${address.city}, ${address.street} ${address.house}${address.flat ? ', ' + t('authModal.flat') + ' ' + address.flat : ''}`,
                                }
                            }
                        ),
                    })

                    const responseUser = await api.get('/user')

                    userDispatch({
                        type: 'setUser',
                        payload: responseUser.data,
                    })
                } catch (err) {
                    console.warn(err)
                }
            }
        } catch (err) {
            console.warn(err)
        }
    }

    useEffect(() => {
        form.setFieldsValue({
            userName: userState?.user?.userName,
            email: userState?.user?.email,
            phone: userState?.user?.phone,
            addressId: userState?.user?.addressId,
            deliveryTime: dayjs(),
            telegram: userState?.user?.telegram,
        })
    }, [userState, form])

    useEffect(() => {
        setIsRegister(!userState.isAuthorized)
    }, [userState.isAuthorized])

    const [calcSum, setCalcSum] = useState(0)
    const [amountForFreeDelivery, setAmountForFreeDelivery] = useState(0)
    const [calcDiscountSum, setCalcDiscountSum] = useState(0)

    useEffect(() => {
        const sum = Object.keys(basketContext.products).reduce(
            (acc: number, key: string) => {
                if (userState.products) {
                    acc +=
                        basketContext.products[key].count *
                        userState.products[key].price
                }

                return acc
            },
            0
        )

        setCalcSum(sum)
    }, [basketContext.products, userState.products])

    useEffect(() => {
        setAmountForFreeDelivery(
            calcSum > 0 ? 10000 - (calcSum - calcDiscountSum) : 0
        )
    }, [calcSum, calcDiscountSum])

    useEffect(() => {
        if (userState.isAuthorized && userState.user?.promoCode) {
            const { type, discount } = userState.user.promoCode
            setCalcDiscountSum(
                type === 'amount' ? discount : (calcSum / 100) * discount
            )
        } else {
            setCalcDiscountSum(0)
        }
    }, [calcSum, userState])

    const [isRegister, setIsRegister] = useState<boolean>(
        !userState.isAuthorized
    )

    const options = [
        { label: t('basketPage.registrationOption1'), value: true },
        { label: t('basketPage.registrationOption2'), value: false },
    ]

    const onChange = (e: RadioChangeEvent) => {
        setIsRegister(e.target.value)
    }

    const style: React.CSSProperties = {
        width: '100%',
    }

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://coffochka.am/basket" />
            </Helmet>

            <div className="BasketPage">
                <div className="BasketPageWrapper">
                    <div className="BasketPageForm">
                        <h1 className="BasketPageTitle">
                            {t('basketPage.title')}
                        </h1>

                        {!userState.isAuthorized ? (
                            <Row gutter={[24, 0]}>
                                <Col>
                                    <Form.Item
                                        layout="vertical"
                                        label={t('basketPage.register')}
                                    >
                                        <Radio.Group
                                            options={options}
                                            value={isRegister}
                                            optionType="button"
                                            buttonStyle="solid"
                                            size="large"
                                            onChange={onChange}
                                            style={style}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        ) : null}

                        <OrderForm
                            form={form}
                            initialValues={{ deliveryMethod: 'delivery' }}
                            loading={loading}
                            isRegister={isRegister}
                        />
                    </div>

                    <div className="BasketPageBasket">
                        <h1 className="BasketPageTitle">
                            {t('basketPage.text1')}
                        </h1>
                        <div className="BasketPageProductList">
                            {!isEmptyObject(basketContext.products) ? (
                                <>
                                    <BasketProducts
                                        products={basketContext.products}
                                        imgSize="120px"
                                    />
                                </>
                            ) : (
                                t('basketPage.emptyBasket')
                            )}

                            <div className="BasketPageResult">
                                <div className="BasketPageResultContent">
                                    <div className="BasketPageResultBlock BasketPageResultSum">
                                        <div>{t('basketPage.text2')}</div>
                                        <div>{calcSum}</div>
                                    </div>

                                    {calcDiscountSum > 0 ? (
                                        <div className="BasketPageResultBlock BasketPageResultDiscount">
                                            <div>{t('basketPage.text4')}</div>
                                            <div> -{calcDiscountSum}</div>
                                        </div>
                                    ) : null}

                                    <div className="BasketPageResultBlock BasketPageResultTotalSum">
                                        <div>{t('basketPage.text3')}</div>
                                        <div>{calcSum - calcDiscountSum}</div>
                                    </div>
                                </div>

                                <Form
                                    form={form2}
                                    style={{
                                        width: '100%',
                                    }}
                                    disabled={loading}
                                >
                                    <Row gutter={[24, 24]}>
                                        <Col span={24}>
                                            <Form.Item
                                                name="products"
                                                rules={[
                                                    {
                                                        validator: () => {
                                                            if (
                                                                !isEmptyObject(
                                                                    basketContext.products
                                                                )
                                                            ) {
                                                                return Promise.resolve()
                                                            }
                                                            return Promise.reject(
                                                                new Error(
                                                                    'Необходимо добавить продукты!'
                                                                )
                                                            )
                                                        },
                                                    },
                                                ]}
                                                style={{
                                                    lineHeight:
                                                        '20px!important',
                                                    padding: 0,
                                                    margin: 0,
                                                }}
                                            >
                                                <Button
                                                    className="CardButton"
                                                    type="primary"
                                                    shape="round"
                                                    size={'large'}
                                                    style={{
                                                        backgroundColor:
                                                            'var(--color-yellow)',
                                                        width: '100%',
                                                    }}
                                                    onClick={onFinish}
                                                    icon={<ShoppingOutlined />}
                                                    loading={loading}
                                                >
                                                    {t('basketPage.button')}
                                                </Button>

                                                {amountForFreeDelivery > 0 ? (
                                                    <Trans
                                                        i18nKey="basketPage.amountForFreeDelivery"
                                                        count={
                                                            amountForFreeDelivery
                                                        }
                                                    >
                                                        <div
                                                            className="ant-form-item-explain"
                                                            style={{
                                                                textAlign:
                                                                    'left',
                                                            }}
                                                        >
                                                            <strong></strong>
                                                        </div>
                                                    </Trans>
                                                ) : null}

                                                {isExistUser ? (
                                                    <p className="ErrorAuth ant-form-item-explain-error">
                                                        {t(
                                                            'basketPage.userExists'
                                                        )}
                                                    </p>
                                                ) : null}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BasketPage
