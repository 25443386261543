import React from 'react'
import { HeartFilled, HeartOutlined } from '@ant-design/icons'
import './Favorite.css'

interface FavoriteProps {
    active: boolean
    onClick: () => void
}

const Favorite = ({ active, onClick }: FavoriteProps) => {
    return (
        <div onClick={onClick}>
            {active ? <HeartFilled /> : <HeartOutlined />}
        </div>
    )
}

export default Favorite
