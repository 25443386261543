import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Divider, Form, Input, Row, Select, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { UserAddress } from '../../contexts'

const { TextArea } = Input

interface AddressFormProps {
    options: UserAddress[];
    value?: string;
    onChange: (value: string) => void;
}

const colProps = {
    xs: { flex: '100%' },
    sm: { flex: '100%' },
    md: { flex: '100%' },
    lg: { flex: '50%' },
    xl: { flex: '50%' },
}

export const AddressForm = ({ value, options, onChange }: AddressFormProps) => {
    const { t } = useTranslation()

    const [openAddressForm, setOpenAddressForm] = useState<boolean>(false)

    return (
        <Form.Item name="address" label={t('orderForm.selectAddress')}>
            {options.length && !openAddressForm ? (
                <Row gutter={[24, 0]}>
                    <Col {...colProps}>
                        <Select
                            showSearch
                            placeholder={t('orderForm.selectAddress')}
                            optionLabelProp="label"
                            onChange={onChange}
                            options={options}
                            defaultValue={value}
                            style={{
                                width: '100%',
                            }}
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <Space style={{ padding: '0 8px 4px' }}>
                                        <Button
                                            type="text"
                                            icon={<PlusOutlined />}
                                            onClick={() => setOpenAddressForm(true)}
                                        >
                                            {t('orderForm.otherAddress')}
                                        </Button>
                                    </Space>
                                </>
                            )}
                        />
                    </Col>
                </Row>
            ) : (
                <>
                    <Row gutter={[24, 0]}>
                        <Col {...colProps}> 
                            <Form.Item
                                    name="city"
                                    label={t('orderForm.city')}
                                    rules={[
                                        {
                                            required: true,
                                            message: t(
                                                'orderForm.cityValidationMessage'
                                            ),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t('orderForm.city')} />
                            </Form.Item>
                        </Col>

                        <Col {...colProps}>
                            <Form.Item
                                    name="street"
                                    label={t('orderForm.street')}
                                    rules={[
                                        {
                                            required: true,
                                            message: t(
                                                'orderForm.streetValidationMessage'
                                            ),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t('orderForm.street')} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[24, 0]}>
                        <Col {...colProps}>
                            <Form.Item name="house" label={t('orderForm.house')}>
                                <Input placeholder={t('orderForm.house')} />
                            </Form.Item>
                        </Col>

                        <Col {...colProps}>
                            <Form.Item name="flat" label={t('orderForm.flat')}>
                                    <Input placeholder={t('orderForm.flat')} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item
                                name="comment"
                                label={t('orderForm.comment')}
                            >
                                <TextArea
                                    rows={4}
                                    placeholder={t('orderForm.comment')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            )}
        </Form.Item>
    )
}
