import React, { useContext, createContext } from 'react'
import { Button, notification } from 'antd'
import {
    FrownOutlined,
    ShoppingOutlined,
    SmileOutlined,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

export interface NotificationState {
    openErrorNotification: ({
        title,
        description,
    }: {
        title?: string
        description?: string
    }) => void
    addToBaskeNotification: () => void
    submitOrderNotification: () => void
    welcomeNotification: (userName: string) => void
    updateUserNotification: () => void
    setFavoriteProductNotification: () => void
}

const NotificationStateContextProvider = createContext<
    NotificationState | undefined
>(undefined)

export const useNotificationStateContext = (): NotificationState => {
    const context = useContext(NotificationStateContextProvider)
    if (context === undefined) {
        throw new Error(
            'useNotificationStateContext must be used within a useNotificationStateContextProvider'
        )
    }
    return context
}

export const NotificationStateContext: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    const [api, contextHolder] = notification.useNotification()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { search } = useLocation()

    const setFavoriteProductNotification = () => {
        api.open({
            message: t('notification.favoriteProductNotification'),
            icon: <SmileOutlined style={{ color: 'var(--color-yellow)' }} />,
            placement: 'bottomRight',
            style: {
                textTransform: 'inherit',
                color: 'var(--color-yellow)',
            },
        })
    }

    const addToBaskeNotification = () => {
        api.open({
            message: t('homePage.notification.addToBasket.title'),
            icon: <SmileOutlined style={{ color: 'var(--color-yellow)' }} />,
            placement: 'bottomRight',
            btn: (
                <Button
                    className="CardButton"
                    type="primary"
                    shape="round"
                    size={'large'}
                    style={{ backgroundColor: 'var(--color-yellow)' }}
                    onClick={() => navigate('/basket' + search)}
                    icon={<ShoppingOutlined />}
                >
                    {t('homePage.notification.addToBasket.button')}
                </Button>
            ),
            style: {
                textTransform: 'inherit',
                color: 'var(--color-yellow)',
            },
        })
    }

    const submitOrderNotification = () => {
        api.open({
            message: t('notification.submitOrderNotificationMsg'),
            description: t('notification.submitOrderNotificationDesc'),
            icon: <SmileOutlined style={{ color: 'var(--color-yellow)' }} />,
            placement: 'bottomRight',
            style: {
                textTransform: 'inherit',
                color: 'var(--color-yellow)',
            },
        })
    }

    const welcomeNotification = (userName: string) => {
        api.open({
            message: `${t('notification.welcomeNotification')}, ${userName}!`,
            icon: <SmileOutlined style={{ color: 'var(--color-yellow)' }} />,
            placement: 'bottomRight',
            style: {
                textTransform: 'inherit',
                color: 'var(--color-yellow)',
            },
        })
    }

    const updateUserNotification = () => {
        api.open({
            message: t('notification.updateUserNotification'),
            icon: <SmileOutlined style={{ color: 'var(--color-yellow)' }} />,
            placement: 'bottomRight',
            style: {
                textTransform: 'inherit',
                color: 'var(--color-yellow)',
            },
        })
    }

    const openErrorNotification = ({
        title,
        description,
    }: {
        title?: string
        description?: string
    }) => {
        api.open({
            message: title ?? t('notification.openErrorNotification'),
            description: description,
            icon: <FrownOutlined style={{ color: 'var(--color-yellow)' }} />,
            placement: 'bottomRight',
            style: {
                textTransform: 'inherit',
                color: 'var(--color-yellow)',
            },
        })
    }

    return (
        <NotificationStateContextProvider.Provider
            value={{
                openErrorNotification,
                addToBaskeNotification,
                submitOrderNotification,
                welcomeNotification,
                updateUserNotification,
                setFavoriteProductNotification,
            }}
        >
            {contextHolder}
            {children}
        </NotificationStateContextProvider.Provider>
    )
}
