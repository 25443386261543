import React from 'react'
import { Helmet } from 'react-helmet'
import { Button } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import './NotFoundPage.css'

const NotFoundPage = () => {
    const navigate = useNavigate()
    const { search } = useLocation()

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://coffochka.am/404" />
            </Helmet>

            <div className="NotFoundPage">
                <h1 className="NotFoundPageTitle">Not Found Page</h1>
                <Button
                    type="primary"
                    size="large"
                    onClick={() => navigate('/' + search)}
                    style={{ backgroundColor: 'var(--color-yellow)' }}
                >
                    Go to the main page
                </Button>
            </div>
        </>
    )
}

export default NotFoundPage
