import React from 'react'
import LazyLoad from 'react-lazyload'
import { Product, Products, useUserStateContext } from '../../contexts'
import { Card } from '../Card'
import { debounceAsync } from '../../utils/throttle'
import { api } from '../../services'
import './Cards.css'

interface CardsProps {
    products: Products
    filters: number[]
    searchFilter: string
    onClick?: () => void
}

const updateFavoriteProducts = debounceAsync(
    async (favoriteProducts: number[]) => {
        return await api.patch('/user/favorite/products', {
            favoriteProducts,
        })
    },
    2000
)

const Cards = ({ products, filters, searchFilter, onClick }: CardsProps) => {
    const userState = useUserStateContext()

    const isTextInclude = (product: Product) => {
        return (
            (searchFilter.length &&
                product.itemName
                    .toLocaleLowerCase()
                    .includes(searchFilter.toLocaleLowerCase())) ||
            (searchFilter.length &&
                product.description
                    .toLocaleLowerCase()
                    .includes(searchFilter.toLocaleLowerCase())) ||
            searchFilter === ''
        )
    }

    const getProducts = () => {
        return Object.keys(products).map((key) => {
            if (isTextInclude(products[key])) {
                if (!filters.length) {
                    return (
                        <LazyLoad
                            throttle={200}
                            height={473}
                            key={products[key].id}
                        >
                            <Card
                                {...products[key]}
                                onClick={onClick}
                                // @ts-expect-error
                                updateFavoriteProducts={updateFavoriteProducts}
                            />
                        </LazyLoad>
                    )
                }

                if (
                    filters.includes(+products[key].category) ||
                    (filters.includes(0) &&
                        (userState.user?.favoriteProducts || []).includes(
                            +products[key].id
                        ))
                ) {
                    return (
                        <Card
                            {...products[key]}
                            key={products[key].id}
                            onClick={onClick}
                            // @ts-expect-error
                            updateFavoriteProducts={updateFavoriteProducts}
                        />
                    )
                }
            }
        })
    }

    return <div className="Cards">{getProducts()}</div>
}

export default Cards
