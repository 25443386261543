import { Address } from '../contexts'

export const addressToClientFormat = (address: Address) => {
    return {
        label: `${address.city}, ${address.street} ${address.house}${address.flat ? ', ' + address.flat : ''}`,
        value: address.id,
    }
}

export const addressesToClientFormat = (addresses: Address[]) => {
    return addresses.map((a) => addressToClientFormat(a))
}
