import React, { useContext, useReducer, createContext } from 'react'

type Action =
    | {
          type: 'addProduct'
          payload: BasketProduct
      }
    | {
          type: 'clearBasket'
      }
    | {
          type: 'removeProduct'
          payload: number
      }
    | {
          type: 'updateProduct'
          payload: BasketProduct
      }

type Dispatch = (action: Action) => void

export interface BasketProduct {
    id: number
    count: number
}

export interface BasketProducts {
    [key: string]: BasketProduct
}

export interface BasketState {
    products: BasketProducts
}

// TODO https://www.geeksforgeeks.org/strategy-method-javascript-design-pattern/
const createInitialState = (
    initParams?: Partial<BasketState>
): BasketState => ({
    products: {},
    ...initParams,
})

const BasketStateContextProvider = createContext<BasketState | undefined>(
    undefined
)
const BasketStateDispatchContext = createContext<Dispatch | undefined>(
    undefined
)

export const useBasketStateContext = () => {
    const context = useContext(BasketStateContextProvider)
    if (context === undefined) {
        throw new Error(
            'useBasketStateContext must be used within a useBasketStateContextProvider'
        )
    }
    return context
}

export const useBasketStateDispatch = () => {
    const context = useContext(BasketStateDispatchContext)
    if (context === undefined) {
        throw new Error(
            'useBasketStateDispatch must be used within BasketStateDispatchContext'
        )
    }
    return context
}

const appStateReducer = (state: BasketState, action: Action): BasketState => {
    let newProduct!: BasketProduct
    let newProducts!: { [x: string]: BasketProduct }

    switch (action.type) {
        case 'addProduct':
            newProduct = action.payload

            if (state.products[action.payload.id]) {
                newProduct = {
                    ...state.products[action.payload.id],
                    count:
                        state.products[action.payload.id].count +
                        action.payload.count,
                }
            }

            return {
                ...state,
                products: {
                    ...state.products,
                    [newProduct?.id]: newProduct,
                },
            }
        case 'clearBasket':
            return {
                ...state,
                products: {},
            }
        case 'removeProduct':
            newProducts = {
                ...state.products,
            }

            delete newProducts[action.payload]

            return {
                ...state,
                products: newProducts,
            }
        case 'updateProduct':
            newProducts = { ...state.products }

            newProducts[action.payload.id] = action.payload

            return {
                ...state,
                products: newProducts,
            }
        default:
            return state
    }
}

export interface IBasketStateProviderProps {
    initialState?: BasketState | undefined
}

export const BasketStateContext: React.FC<
    React.PropsWithChildren<IBasketStateProviderProps>
> = ({ initialState, children }) => {
    const [state, dispatch] = useReducer(
        appStateReducer,
        createInitialState(initialState)
    )

    return (
        <BasketStateContextProvider.Provider value={state}>
            <BasketStateDispatchContext.Provider value={dispatch}>
                {children}
            </BasketStateDispatchContext.Provider>
        </BasketStateContextProvider.Provider>
    )
}
