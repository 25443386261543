import { useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { api } from '../../services'
import { useUserStateDispatch } from '../../contexts'

export const ResponseInterceptor = () => {
    const navigate = useNavigate()
    const { search } = useLocation()
    const location = useLocation()
    const userDispatch = useUserStateDispatch()

    const interceptorId = useRef(null)

    useEffect(() => {
        // @ts-expect-error
        interceptorId.current = api.interceptors.response.use(
            undefined,
            (error) => {
                switch (error?.response?.status) {
                    case 401:
                        Cookies.remove('Authorization')

                        userDispatch({
                            type: 'deleteUser',
                        })

                        if (location.pathname === '/account') {
                            navigate('/' + search)
                        }

                        return Promise.reject(error)
                    // your processing here
                    default:
                        return Promise.reject(error)
                }
            }
        )
    }, [navigate])

    return null
}
