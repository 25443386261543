import { Category } from '../contexts'

export const sortCategories = (categories: Category[]) => {
    return categories.sort((a: Category, b: Category) => {
        if (a.sort > b.sort) {
            return 1
        }
        if (a.sort < b.sort) {
            return -1
        }
        return 0
    })
}
